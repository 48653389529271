// 
// 
// user.scss
//
// Place your own sparrow CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
#photoCredit {
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  height: auto;
  padding: 2px 8px;
  z-index: 2;
  background-color: rgb(255,255,255);
  background-color: rgba(255,255,255,.75);
  color: #111;
}

/* unvisited link */
#photoCredit a:link {
  color: black;
  font-weight: 900;
}

/* visited link */
#photoCredit a:visited {
  color: black;
}

/* mouse over link */
#photoCredit a:hover {
  color: #fb0200;
  text-decoration: none;
}

/* selected link */
#photoCredit a:active {
  color: black;
}

.fancynavbar-collapse {
  z-index: -1;
}

.dss-logo-serif {
    font-family: 'Libre Baskerville', serif;
    color: #333333;
    font-size: 3.85rem;
}

.dss-logo-sans {
    font-family: 'DM Sans', sans-serif;
    letter-spacing: -3px;
}

.dss-logo-red {
    color: #fb0200;
}

.status h4 {

    color: green;
    margin-top: 15px;
    padding: 0px;
}

.status span {
    color: red;
}

/* unvisited link */
  a:link.dss-home-btn {
    color: black;
  }
  
  /* visited link */
  a:visited.dss-home-btn {
    color: black;
  }
  
  /* mouse over link */
  a:hover.dss-home-btn {
    color: #fb0200;
    text-decoration: none;
  }
  
  /* selected link */
  a:active.dss-home-btn  {
    color: black;
  }

  .proj_thumb_label {
    color:white;
    float:right;
    padding-left: 10px;
  }


  /* unvisited link */
  a:link.dss-testimonial-link {
    color: white;
  }
  
  /* visited link */
  a:visited.dss-testimonial-link {
    color: white;
  }
  
  /* mouse over link */
  a:hover.dss-testimonial-link {
    text-decoration: underline;
  }
  
  /* selected link */
  a:active.dss-testimonial-link  {
    color: white;
  }


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
  //CSS
  
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
  //CSS
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  //CSS

  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  //CSS

  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
  //CSS
  .fancynavbar-togglerbar {
    background-color: black !important;
  }

  #landing_page_image {
    margin-top: 60px;
  }

  #main_project {
    overflow:auto;
  }

  #main_project_image_container {
    height: max-content !important;
  }

  #main_project_description {
    padding-top: 20px !important;
  }

  #main_project_image {
    margin-top: 60px;
    display: block !important;
    width: 100% !important;
    height: auto !important;
  }
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  //CSS
  .fancynavbar-togglerbar {
    background-color: black !important;
  }

  #landing_page_image {
    margin-top: 60px;
  }

  #main_project {
    overflow:auto;
  }

  #main_project_image_container {
    height: max-content !important;
  }

  #main_project_description {
    padding-top: 20px !important;
  }

  #main_project_image {
    margin-top: 60px;
    display: block !important;
    width: 100% !important;
    height: auto !important;
  }
  
}